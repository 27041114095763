{
  "familyName": "Pojtinger",
  "parents": [
    {
      "name": "Nette",
      "username": "@an.nette.pojtinger",
      "followLink": "https://www.instagram.com/an.nette.pojtinger/",
      "emailLink": "mailto:nette@pojtinger.com",
      "path": "/nette",
      "avatarLink": "https://cloud.pojtinger.com/s/Pbf3zp3XrYb8Gqr/download?path=%2Fpojtinger.com&files=Nette.jpg"
    },
    {
      "name": "Michael",
      "username": "@michaelpojtinger",
      "followLink": "https://www.instagram.com/michaelpojtinger/",
      "emailLink": "mailto:michael@pojtinger.com",
      "path": "/michael",
      "avatarLink": "https://cloud.pojtinger.com/s/Pbf3zp3XrYb8Gqr/download?path=%2Fpojtinger.com&files=Michael.jpg"
    }
  ],
  "children": [
    {
      "name": "Toni",
      "username": "@toni.pojtinger",
      "followLink": "https://www.instagram.com/toni.pojtinger/",
      "emailLink": "mailto:toni@pojtinger.com",
      "path": "/toni",
      "avatarLink": "https://cloud.pojtinger.com/s/Pbf3zp3XrYb8Gqr/download?path=%2Fpojtinger.com&files=Toni.jpg"
    },
    {
      "name": "Luis",
      "username": "@luispojtinger",
      "followLink": "https://www.instagram.com/luispojtinger/",
      "emailLink": "mailto:luis@pojtinger.com",
      "path": "/luis",
      "avatarLink": "https://cloud.pojtinger.com/s/Pbf3zp3XrYb8Gqr/download?path=%2Fpojtinger.com&files=Luis.jpg"
    },
    {
      "name": "Felicitas",
      "username": "@pojntfx",
      "followLink": "https://felicitas.pojtinger.com/",
      "emailLink": "mailto:felicitas@pojtinger.com",
      "path": "/felicitas",
      "remoteLink": "https://felicitas.pojtinger.com/",
      "avatarLink": "https://github.com/pojntfx.png"
    }
  ]
}
